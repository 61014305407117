<template>
  <div>
    <b-row class="content-header">
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-1"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item :to="{name: 'faq'}">
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="20"
                    class="align-text-top"
                  />
                  {{ $t('All questions') }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- search input -->
    <section id="kb-category-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/pages/help/FAQ_banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h1 class="text-primary">
            {{ $t("Frequently asked questions") }}
          </h1>
          <b-card-text class="mb-2">
            <span>{{ $t('Find here answers to your questions') }}</span>
          </b-card-text>

          <div class="kb-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="knowledgeBaseSearchQuery"
                :placeholder="$t('Ask a question')"
              />
            </b-input-group>
          </div>
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <b-row
      id="questions_row"
      class="d-flex justify-content-center"
    >
      <b-col
        md="8"
        class="pt-4"
      >
        <div>
          <h3 class="font-weight-bolder text-center mb-3">
            {{ $t(currentCategory.title) }}
          </h3>
          <app-collapse
            accordion
            type="margin"
          >
            <app-collapse-item
              v-for="(question, index) in filteredQuestions"
              :key="index"
              title=""
            >
              <template #header>
                <b>{{ $t(question.question) }}</b>
              </template>
              <!-- eslint-disable-next-line -->
              <span v-html="$t(question.answer)" />
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-col>
    </b-row>

    <have-questions-internal v-if="!knowledgeBaseSearchQuery" />
  </div>
</template>

<script>
import {
  BRow,
  BBreadcrumb,
  BBreadcrumbItem,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HaveQuestionsInternal from '@mentoring-platform/views/components/blocks/Help/HaveQuestionsInternal.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BBreadcrumb,
    BBreadcrumbItem,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    HaveQuestionsInternal,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [
        {
          id: 1,
          key: 'mentoring-in-general',
          title: 'Mentoring in general',
          icon: 'SettingsIcon',
          iconColor: 'text-primary',
          questions: [
            {
              id: 1,
              question: 'Faq - mentoring-in-general - question 1',
              slug: 'how-secure-is-my-password',
              answer: 'Faq - mentoring-in-general - answer 1',
            },
            {
              id: 5,
              question: 'Faq - for-mentees - question 20',
              slug: 'how-do-i-change-my-timezone',
              answer: 'Faq - for-mentees - answer 20',
            },
            {
              id: 2,
              question: 'Faq - mentoring-in-general - question 2',
              slug: 'can-i-change-my-username',
              answer: 'Faq - mentoring-in-general - answer 2',
            },
            {
              id: 3,
              question: 'Faq - mentoring-in-general - question 3',
              slug: 'where-can-i-upload-my-avatar',
              answer: 'Faq - mentoring-in-general - answer 3',
            },
            {
              id: 4,
              question: 'Faq - mentoring-in-general - question 4',
              slug: 'how-do-i-change-my-timezone',
              answer: 'Faq - mentoring-in-general - answer 4',
            },
          ],
        },
        {
          id: 2,
          key: 'for-mentors',
          title: 'For mentors',
          icon: 'SettingsIcon',
          iconColor: 'text-primary',
          questions: [
            {
              id: 1,
              question: 'Faq - for-mentors - question 1',
              slug: 'how-secure-is-my-password',
              answer: 'Faq - for-mentors - answer 1',
            },
            {
              id: 2,
              question: 'Faq - for-mentors - question 2',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 2',
            },
            {
              id: 3,
              question: 'Faq - for-mentors - question 3',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 3',
            },
            {
              id: 4,
              question: 'Faq - for-mentors - question 4',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 4',
            },
            {
              id: 19,
              question: 'Faq - for-mentors - question 19',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 19',
            },
            {
              id: 5,
              question: 'Faq - for-mentors - question 5',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 5',
            },
            {
              id: 6,
              question: 'Faq - for-mentors - question 6',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 6',
            },
            {
              id: 7,
              question: 'Faq - for-mentors - question 7',
              slug: 'what-gww-means',
              answer: 'Faq - for-mentors - answer 7',
            },
            {
              id: 8,
              question: 'Faq - for-mentors - question 8',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 8',
            },
            {
              id: 9,
              question: 'Faq - for-mentors - question 9',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 9',
            },
            {
              id: 10,
              question: 'Faq - for-mentors - question 10',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 10',
            },
            {
              id: 11,
              question: 'Faq - for-mentors - question 11',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 11',
            },
            {
              id: 12,
              question: 'Faq - for-mentors - question 12',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 12',
            },
            {
              id: 13,
              question: 'Faq - for-mentors - question 13',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 13',
            },
            {
              id: 14,
              question: 'Faq - for-mentors - question 14',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 14',
            },
            {
              id: 15,
              question: 'Faq - for-mentors - question 15',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 15',
            },
            {
              id: 16,
              question: 'Faq - for-mentors - question 16',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 16',
            },
            {
              id: 17,
              question: 'Faq - for-mentors - question 17',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 17',
            },
            {
              id: 18,
              question: 'Faq - for-mentors - question 18',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentors - answer 18',
            },
          ],
        },
        {
          id: 3,
          key: 'for-mentees',
          title: this.$t('For mentees'),
          icon: 'SettingsIcon',
          iconColor: 'text-primary',
          questions: [
            {
              id: 1,
              question: 'Faq - for-mentees - question 1',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 1',
            },
            {
              id: 20,
              question: 'Faq - for-mentees - question 20',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 20',
            },
            {
              id: 2,
              question: 'Faq - for-mentees - question 2',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 2',
            },
            {
              id: 3,
              question: 'Faq - for-mentees - question 3',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 3',
            },
            {
              id: 4,
              question: 'Faq - for-mentees - question 4',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 4',
            },
            {
              id: 5,
              question: 'Faq - for-mentees - question 5',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 5',
            },
            {
              id: 6,
              question: 'Faq - for-mentees - question 6',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 6',
            },
            {
              id: 7,
              question: 'Faq - for-mentees - question 7',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 7',
            },
            {
              id: 8,
              question: 'Faq - for-mentees - question 8',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 8',
            },
            {
              id: 9,
              question: 'Faq - for-mentees - question 9',
              slug: 'what-gww-means',
              answer: 'Faq - for-mentees - answer 9',
            },
            {
              id: 10,
              question: 'Faq - for-mentees - question 10',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 10',
            },
            {
              id: 11,
              question: 'Faq - for-mentees - question 11',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 11',
            },
            {
              id: 12,
              question: 'Faq - for-mentees - question 12',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 12',
            },
            {
              id: 13,
              question: 'Faq - for-mentees - question 13',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 13',
            },
            {
              id: 14,
              question: 'Faq - for-mentees - question 14',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 14',
            },
            {
              id: 15,
              question: 'Faq - for-mentees - question 15',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 15',
            },
            {
              id: 16,
              question: 'Faq - for-mentees - question 16',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 16',
            },
            {
              id: 17,
              question: 'Faq - for-mentees - question 17',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 17',
            },
            {
              id: 18,
              question: 'Faq - for-mentees - question 18',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 18',
            },
            {
              id: 19,
              question: 'Faq - for-mentees - question 19',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 19',
            },
            {
              id: 21,
              question: 'Faq - for-mentees - question 21',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 21',
            },
            {
              id: 22,
              question: 'Faq - for-mentees - question 22',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 22',
            },
            {
              id: 23,
              question: 'Faq - for-mentees - question 23',
              slug: 'can-i-change-my-username',
              answer: 'Faq - for-mentees - answer 23',
            },
          ],
        },
      ],
    }
  },
  computed: {
    currentCategory() {
      return this.kb.find(category => category.key === this.$route.params.category)
    },
    filteredQuestions() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.currentCategory.questions.filter(item => {
        const question = this.$t(item.question)
        const answer = this.$t(item.answer)
        return question.toLowerCase().includes(knowledgeBaseSearchQueryLower) || answer.toLowerCase().includes(knowledgeBaseSearchQueryLower)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

#questions_row {
  margin-bottom: 5rem;
}

.breadcrumb-wrapper {
  .breadcrumb {
    padding-left: 0;
    padding-bottom: 0;
  }
}
</style>
