<template>
  <div class="d-flex flex-column align-items-center">
    <div class="col-12 text-center">
      <b-card-title>
        {{ $t('Do you have more questions?') }}
      </b-card-title>
    </div>
    <div class="col-sm-8 col-md-4 text-center">
      {{ $t('See all our(female)') }}
      <b-link :to="{name: 'faq'}">
        {{ $t('frequently asked questions') }}
      </b-link>
      {{ $t('or') }} {{ $t('contact us') }}
      <a href="mailto:info@when.org.gr">info@when.org.gr</a>
    </div>
  </div>
</template>

<script>
import {
  BCardTitle,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCardTitle,
    BLink,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
